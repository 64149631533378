import React from 'react';

import state from '../../state/state';

import { i18n } from '../i18n';

import logo from './images/logo_sm.png';

import css from './index.module.css';

export default () => (
    <div className={ css.cta }>
        <div className={ css.container }>
            <img
                loading='lazy'
                alt='Guild Wars 2'
                src={ logo }
            />
            <h2 className={ css.header }>{ i18n('reg.header') }</h2>
            <p className={ css.para }>{ i18n('reg.para') }</p>
            <a
                className='button gtm-signup'
                href={ state.regLink }
                target='_blank'
                rel='noopener noreferrer'
            >
                { i18n('nag.cta') }
            </a>
        </div>
    </div>
);
