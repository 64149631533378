import React, { useState } from 'react';

import { i18n } from '../i18n';

import css from './index.module.css';

import bltc from './images/bltc_icon.png';

import carousel0 from './images/carousel-0.png';
import carousel1 from './images/carousel-1.png';
import carousel2 from './images/carousel-2.png';

import carousel0Sm from './images/carousel-0-sm.png';
import carousel1Sm from './images/carousel-1-sm.png';
import carousel2Sm from './images/carousel-2-sm.png';

const imgs = [{
        full  : carousel0,
        thumb : carousel0Sm
    }, {
        full  : carousel1,
        thumb : carousel1Sm
    }, {
        full  : carousel2,
        thumb : carousel2Sm
    }];

function isClient() {
    return typeof window !== 'undefined';
}

function getSize() {
    return isClient() ? window.matchMedia('(min-width: 1000px),(max-width:568px').matches : true;
}

export default function Couture() {
    const [ largeSize, setSize ] = useState(getSize());

    if (isClient()) {
        window.addEventListener('resize', () => {
            const newLargeSize = getSize();

            if (largeSize === newLargeSize) {
                return;
            }

            setSize(newLargeSize);
        });
    }

    return (
        <div className={ css.couture } id='couture'>
            <div className={ css.constrained }>
                <div className={ css.copy }>
                    <img
                        loading='lazy'
                        alt=''
                        src={ bltc }
                    />
                    <h2 className={ css.header }>{ i18n('couture.header') }</h2>
                    <h3 className={ css.tagline }>{ i18n('couture.tagline') }</h3>
                    <p className={ css.para }>{ i18n('couture.para') }</p>
                </div>
                <div className={ css.carousel }>
                    {
                        imgs.map(({ full, thumb }, idx) => (
                            <img
                                src={ largeSize ? full : thumb }
                                alt=''
                                key={ idx }
                                style={{ animationDelay : `-${idx * 3.5}s` }}
                                loading='lazy'
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
