import React from 'react';

import { i18n } from '../i18n';

import story   from './images/story_icon.png';
import combat  from './images/combat_icon.png';
import mastery from './images/mastery_icon.png';
import bosses  from './images/bosses_icon.png';

import css from './index.module.css';

const src = {
    story,
    combat,
    mastery,
    bosses
};
const reasons = Object.keys(src);

export default () => (
    <div className={ css.adventure } id="adventure">

        <div className={ css.headerContainer }>
            <h2 className={ css.header }>{ i18n('adventure.header') }</h2>
        </div>

        <div className={ css.reasons }>
            {
                reasons.map((reason, idx) => {
                    const prefix = `adventure.reasons.${idx}`;

                    return (
                        <div className={ css.reasonContainer } key={ idx }>
                            <img
                                loading='lazy'
                                alt=''
                                src={ src[reason] }
                            />

                            <h3 className={ css[reason] }>
                                { i18n(`${prefix}.header`) }
                            </h3>

                            <p className={ css.para }>{ i18n(`${prefix}.para`) }</p>

                        </div>
                    );
                })
            }
        </div>

    </div>
);
