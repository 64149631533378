import React  from 'react';
import Helmet from 'react-helmet';

import { i18n } from './i18n';

const links = [
    'https://origin-static.ncplatform.net/pure/0.6.0/pure.css',
    'https://origin-static.ncplatform.net/fonts/cronos/v1/cronos-light-min.css',
    'https://origin-static.ncplatform.net/fonts/cronos/v1/cronos-regular-min.css',
    'https://origin-static.ncplatform.net/fonts/cronos/v1/cronos-bold-min.css',
    'https://origin-static.ncplatform.net/fonts/cronos/v1/cronos-italic-min.css',
    'https://origin-static.ncplatform.net/fonts/cronos/v1/cronos-bolditalic-min.css',
    'https://origin-static.ncplatform.net/fonts/eason/v1/eason-regular-min.css',
    'https://origin-static.ncplatform.net/fonts/eason/v1/eason-italic-min.css',
    'https://origin-static.ncplatform.net/fonts/eason/v1/eason-bold-min.css',
    'https://origin-static.ncplatform.net/fonts/eason/v1/eason-displaycaps-min.css',
    'https://use.typekit.net/dpy4rht.css'
].map(href => ({
    href,
    rel : 'stylesheet'
}));

export default ({ lang, langs, metatags }) => {
    // don't filter out current page, https://support.google.com/webmasters/answer/189077?hl=en
    langs.forEach(hreflang => {
        links.push({
            rel  : 'alternate',
            // todo: this shouldn't be hard-coded
            href : `https://welcome.guildwars2.com/${hreflang}/play-guild-wars-2`,
            hreflang
        });
    });

    return (
        <Helmet link={links}>
            <html lang={lang} />
            <title>{ i18n('title', true) }</title>
            {
                Object.entries(metatags).map(([ property, content ]) => (
                    <meta key={property} property={property} content={content} />
                ))
            }
            <meta property='twitter:card' content='summary' />
        </Helmet>
    );
};
