import React from 'react';

import Logotype from '../logotype';
import { i18n } from '../i18n';

import videoBg from './images/bg.mp4';
import playBtn from './images/play_button.png';

import state from '../../state/state';

import css from './index.module.css';

// Video BG
class Video extends React.Component {
    constructor() {
        super();

        this.state = {
            showVideo : false
        };
        this.handlePlay = this.handlePlay.bind(this);
    }

    handlePlay() {
        this.setState({ showVideo : true });
    }

    render() {
        const { showVideo } = this.state;

        return <video
            className={ showVideo ? css.video : css.videoHide }
            autoPlay={ true }
            onPlay={ this.handlePlay }
            loop={ true }
            muted={ true }
        >
            <source
                src={ videoBg }
                type='video/mp4'
            />
        </video>;
    }
}

export default class Splash extends React.Component {
    constructor(props) {
        super();

        this.state = { video : false };
        this.handleOpeningModal = props.handleOpeningModal;
        this.ydId = props.ytId;
    }

    componentDidMount() {
        if (window.matchMedia('(min-width: 48em)').matches) {
            this.setState({ video : true });
        }
    }

    render() {
        return (
            <div className={ css.splash } id="splash">
                { (this.state.video ? <Video /> : null) }

                <Logotype style={ css.logo } sizeMultiplier={ 1.4 } fill='white' />

                {/* todo: a11y */}
                <button
                    className={ css.play }
                    onClick={ () => {
                        this.handleOpeningModal({ ytId : i18n('splash.ytId', true), alt : i18n('title', true) });
                    } }
                    aria-label={ i18n('ui.play', true) }
                >
                    <img src={ playBtn } alt=''/>
                </button>

                <div className={ css.splashArt }>
                    <div className={ css.copy }>
                        <h1>{ i18n('splash.header') }</h1>
                        <a
                            className='button gtm-p4f'
                            href={ state.regLink }
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            { i18n('splash.cta') }
                        </a>
                        <p className={ css.tagline }>
                            { i18n('splash.tagline.0') }
                            {/* this is a cool way to add a space in JSX -_- */ ' '}
                            { i18n('splash.tagline.1') }
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
