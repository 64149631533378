import React from 'react';

const WIDTH = 360.67;
const HEIGHT = 60.67;

function getDims(factor) {
    return {
        width  : WIDTH * factor,
        height : HEIGHT * factor
    };
}

export default (props) => {
    const { sizeMultiplier, fill, style } = props;
    const { width, height } = sizeMultiplier ?
        getDims(sizeMultiplier) :
        { width : WIDTH, height : HEIGHT };

    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 360.67 60.67'
            className={ style }
            width={ width }
            height={ height }
            fill={ fill || 'black' }
        >
            <title>Guild Wars 2</title>
            <path d='M100.65,47.8c-.55-.58-1-1.22-1-3.94V15c0-2.73.44-3.36,1-3.94,1-1,2-1.12,2.1-1.45s-.29-.58-1.1-.51c-1.4.1-3.08.24-5.36.25s-4-.15-5.36-.25c-.81-.07-1.24.11-1.1.51s1.12.42,2.1,1.45,1,2.35,1,4.91V42.88c0,3.56-.39,4.28-1,4.92-1,1-2,1.12-2.1,1.45s.29.57,1.1.51c1.4-.11,3.08-.25,5.36-.25s4,.14,5.36.25c.81.06,1.24-.11,1.1-.51S101.63,48.83,100.65,47.8Z' />,

            <path d='M132.23,42.07c-.33.12-.45,1.08-1.45,2.1-1.17,1.19-4,2.48-9.5,2.48-2.19,0-3.8-.74-3.8-3.26V15c0-2.74.44-3.37,1-3.95,1-1,2-1.12,2.09-1.45s-.29-.58-1.1-.51c-1.4.1-3.08.24-5.36.25s-4-.15-5.36-.25c-.8-.07-1.24.11-1.09.51s1.07.46,2.09,1.45c.84.81,1,2.34,1,4.91V42.88c0,3.57-.4,4.28-1,4.92-1,1-2,1.12-2.09,1.45s.28.57,1.09.51c3.13-.2,7.9-.33,9.35-.31,3.61.06,8.55.36,9.46.41a10.55,10.55,0,0,0,3.25-.16,1.72,1.72,0,0,0,.78-1.32c.58-3,1-3.81,1.15-5.21C132.81,42.36,132.63,41.93,132.23,42.07Z' />,

            <path d='M85.24,9.14c-1.39.11-2.32.25-3.63.25S79.38,9.25,78,9.14c-.81-.06-1.24.11-1.1.51S78.11,10,79.06,11c.59.61.92,1.44.92,4.73V36.54c0,7.5-5.76,10.37-10.86,10.37s-9.29-3.41-9.29-9.23V15.06c0-2.74.44-3.38,1-4,1-1,2-1.12,2.1-1.44s-.29-.58-1.1-.52c-1.4.11-3.08.25-5.36.25s-4-.14-5.36-.25c-.81-.06-1.24.12-1.1.52s1.12.42,2.1,1.44,1,2.51,1,4.92V36.4c0,9.55,7.75,14.23,14.79,14.23,11.56,0,15.35-7.78,15.36-14.09V16c0-3.57.31-4.43.93-5.06,1-1,2.06-1,2.17-1.31S86.05,9.08,85.24,9.14Z' />,

            <path d='M48.19,26.05c-1.4.11-3.08.25-5.36.25s-4-.14-5.36-.25c-.81-.06-1.24.11-1.09.51S37.49,27,38.47,28c.84.88,1,2.39,1,4.86V43.08c0,1.37.3,1.77,1.72,2.76a15.93,15.93,0,0,1,4,3.65c.17.25.43.42.64.36s.32-.91.32-.91V32c0-2.73.44-3.36,1-3.94,1-1,2-1.12,2.09-1.45S49,26,48.19,26.05Z' />,

            <path d='M29.89,6.89c5.39,0,8,1.29,10.57,3.41a16,16,0,0,1,3.88,5c.78,1.65.66,2.13,1.11,2.33.25.1.73-.13.73-1.11,0-1.56,0-3.61,0-7,0-1.09-.7-1.5-4.13-2.93A32.3,32.3,0,0,0,29.89,4.15C15.93,4.15,6,14.53,6,27.33,6,38.77,13.9,48.06,22,50.45a8.08,8.08,0,0,0,.8.21c.42.07.5-.31.5-1.27,0-.36-.08-.51-.58-1-5.06-4.56-8.58-12.81-8.58-20.77C14.12,15.91,20.92,6.89,29.89,6.89Z' />,

            <path d='M226.34,4.94c-1.07.08-2.29.18-3.82.22a25.65,25.65,0,0,1-3.08-.22c-.81-.06-1.24.11-1.1.51s1.07.91,1.25,1.49c.24.75.3,1.43-.39,3.51L208.71,39.86,196.67,11c-.9-2.53-.85-3.26-.59-4.07.18-.58,1.13-1.16,1.25-1.49s-.29-.57-1.1-.51c-1.4.11-3.08.25-5.36.25s-4-.14-5.36-.25c-.81-.06-1.24.11-1.1.51s1.12.42,2.1,1.45a17.93,17.93,0,0,1,3.06,4.65c.23.5.45,1,.67,1.51l.56,1.34c.12.28.24.57.35.85a.36.36,0,0,1,0,.09l15.14,36.1c.36.84,1,1.38,1.44.16l14.37-39.78h0c1.33-3.51,2.43-4.32,3-4.88,1-1,2.19-1.16,2.3-1.49S227.15,4.88,226.34,4.94ZM197.1,35.7c-.34-.15-.81.2-1.23.87l-2.47,4-12.3-29.4c-.94-2.62-.89-3.36-.63-4.18.18-.58,1.13-1.16,1.25-1.49s-.29-.57-1.1-.51c-1.4.11-3.08.25-5.36.25s-4-.14-5.35-.25c-.81-.06-1.25.11-1.1.51s1.11.42,2.09,1.45a17.27,17.27,0,0,1,3,4.51c.26.54.5,1.09.73,1.63l.58,1.38c.11.28.23.55.34.83l0,.08,15.09,36c.21.51.5.92.82.92a1,1,0,0,0,.71-.67L197,37.69C197.32,36.76,197.52,35.89,197.1,35.7Z' />,

            <path d='M149.81,8.84c-5.61,0-6.35.52-10.78.52-1.76,0-3.13-.14-4.3-.23-.81-.07-1.24.11-1.1.51s1.11.42,2.09,1.45c.83.87,1,2.23,1,4.91V49.16c0,.45.09.83.32.91s.52-.12.71-.35.65-.79,1.28-1.43a17.07,17.07,0,0,1,2.69-2.23c1.45-1.05,1.72-1.43,1.72-2.9V15.84c0-.71.06-2.22.12-2.68a10.9,10.9,0,0,1,6.24-1.47c8.39,0,15.2,6.22,15.2,18,0,7.92-3,14.92-7.7,17.89h0c-.52.37-.66.62-.66,1,0,1,0,1.26.45,1.15l.31-.09c9-2.78,15.44-10.21,15.44-20.33C172.85,16.1,163,8.84,149.81,8.84Z' />,

            <path d='M344.44,39.93c-.33.12-1,1.14-2.06,2.11-1.33,1.19-4.25,2.34-8.82,2.34H322c8.64-11.66,20.11-20.2,20.11-28.55,0-7.6-4.16-11.9-13.94-11.9-3.06,0-8,1.38-12.35,3.91A1.35,1.35,0,0,0,315,9.15V16c0,1.28.48,1.4.73,1.29s.34-.4.57-1.08a18,18,0,0,1,4.88-6.87,9.33,9.33,0,0,1,6.52-2.52c5.67,0,7.4,4.11,7.4,8.43,0,6.64-5.5,13.17-10,19.27-6.11,8.32-10,13.36-11.46,15.29-.16.2-.22.4-.15.53s.42.18.89.16c2.72-.15,9.13-.5,13.88-.4,3.61.07,8.95.36,9.86.41a10.29,10.29,0,0,0,3.25-.15c.46-.22.85-1.19,1-1.74,1.21-3.65,2.31-5.45,2.55-7.55C345.05,40.22,344.84,39.79,344.44,39.93Z' />,

            <path d='M298.51,25.9c-6.49-4.68-8.12-6.67-8.12-9.38,0-2,1.57-4.45,5.54-4.93a9,9,0,0,1,9.23,4.19c1.12,1.91.82,3.75,1.32,4.17.2.18.69-.07.71-1.07,0-1.73,0-6.15,0-7,0-1.08-.87-1.4-2.85-2a29.9,29.9,0,0,0-7.94-1.24c-5.79,0-11.17,2.42-12.22,8-.74,4,.23,8.29,8.06,14.11,6.8,5,9.42,7.19,9.42,10.59s-2.73,6.26-7.76,6.26c-.61,0-1.21,0-1.78-.07-.38,0-.52.11-.4.35s.61,1.14.72,1.31a.9.9,0,0,0,.6.44,19.28,19.28,0,0,0,2.81.13c5.61,0,12.87-3.11,12.87-10.25C308.74,35.07,306.67,31.77,298.51,25.9Z' />,

            <path d='M288.4,56h0v0h0L274.82,33.72a13.3,13.3,0,0,0,5.94-11.22c0-9-6.25-13.48-17-13.48-5,0-5.37.3-8.49.37-2.23,0-3.89-.14-5.27-.25-.81-.06-1.24.11-1.09.52s1.11.42,2.09,1.44,1,2.46,1,4.91V42.89h0a15.33,15.33,0,0,1-.33,3.55c-.33,1.23-1.39,1.34-2.22.53s-1.14-1.76-2.18-4L233,9c-.35-.6-.83-.88-1.28.14L219.54,42.85a10.84,10.84,0,0,1-3.23,5.07c-1,1-1.9.83-2.07,1.3s.29.57,1.1.51c1.07-.08,2.29-.18,3.82-.23a27.77,27.77,0,0,1,3.08.23c.81.06,1.24-.11,1.1-.51s-1.07-.91-1.25-1.49-.36-1.4.32-3.28L230.77,21,238,38.34l-7.67.12c-.66,0-.79.25-1,.82-.35.95-.4,1.22-.27,1.4s.89.21,1.25.21h8.77l1.4,3.38c.71,2.11.65,2.79.41,3.54-.18.58-1.13,1.16-1.25,1.49s.29.57,1.1.51c1.4-.11,3.08-.24,5.35-.25,1.67,0,3.39.12,4.53.1,2.14,0,3.6-.16,4.7-.16,2.28,0,4,.14,5.36.25.81.06,1.24-.11,1.1-.51s-1.12-.42-2.1-1.45c-.55-.58-.92-1.21-1-3.91V15.21a23.29,23.29,0,0,1,.16-2.7,14.69,14.69,0,0,1,4.62-.53c5.06,0,9.62,3.18,9.62,10.52,0,6.87-2.78,11.29-8.92,13.42-.6.21-.72.29-.76.73-.06.74,0,1.16.9,1.08a24.59,24.59,0,0,0,4-.88c1.42,1.53,4.28,4.61,8.39,9.11,3.4,3.71,10.37,10.51,10.37,10.51.69.63,1.18.89,1.43.71S288.75,56.62,288.4,56Z' />,

            <path d='M357.53,13.88a5.3,5.3,0,0,1-10.59,0,5.31,5.31,0,0,1,5.29-5.29,5.33,5.33,0,0,1,5.3,5.29Zm-.75,0a4.38,4.38,0,0,0-1.34-3.21,4.53,4.53,0,0,0-6.42,0,4.54,4.54,0,0,0,0,6.42,4.53,4.53,0,0,0,6.42,0A4.38,4.38,0,0,0,356.78,13.88Zm-2,3.09h-1l-1.18-2.63h-1.69V17h-.91V10.81h2.6a2.12,2.12,0,0,1,1.58.54,1.68,1.68,0,0,1,.52,1.2,1.65,1.65,0,0,1-.29.94,1.54,1.54,0,0,1-.89.64Zm-3.88-3.35h1.59a1.27,1.27,0,0,0,.94-.31,1.08,1.08,0,0,0,.3-.77.85.85,0,0,0-.32-.72,1.35,1.35,0,0,0-.82-.25h-1.69Z' />
        </svg>
    );
};
