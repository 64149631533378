import React from 'react';

import { i18n } from '../i18n';

import divider  from './images/icon.png';
import accolade from './images/accolade.png';
import rytlock  from './images/rytlock_art_top.png';

import css from './index.module.css';

export default () => (
    <div className={ css.intro } id="intro">
        <div className={ css.copy }>
            <h3 className={ css.enter }>{ i18n('world.enter') }</h3>
            <h2 className={ css.tagline }>{ i18n('world.tagline') }</h2>
            <p className={ css.para }>{ i18n('world.para') }</p>
            <img
                className={ css.divider }
                src={ divider }
                alt=''
            />
            <h3 className={ css.best }>{ i18n('world.best') }</h3>
            <img src={ accolade } alt='TechRadar'/>
        </div>

        <img
            className={ css.rytlock }
            loading='lazy'
            src={ rytlock }
            alt='Rytlock'
        />
    </div>
);
