import React from 'react';

import css from './index.module.css';
import { i18n } from '../i18n';

const hdRatio = 16 / 9;
const scrollbarWidth = 18;

function getScreenRatio() {
    return (window.innerWidth - scrollbarWidth) / window.innerHeight < hdRatio;
}

function getSize() {
    const screenRatio = getScreenRatio();

    return {
        width  : screenRatio ? window.innerWidth - scrollbarWidth : window.innerHeight * hdRatio,
        height : screenRatio ? (window.innerWidth - scrollbarWidth) / hdRatio : window.innerHeight
    };
}

function VideoModal({ view, handleExitByClickingImg, ytId, alt }) {
    // Chrome devtools doesn't format correctly when inline
    const src = `https://www.youtube.com/embed/${ytId}?&autoplay=1`;

    return (
        <div className={ css.videoContent }>
            <iframe
                className={ css.iframe }
                // todo: fix title
                title={ alt }
                width={ view.width }
                height={ view.height }
                frameBorder={ 0 }
                allowFullScreen=''
                src={ src }
            />

            <button className={ css.videoClose } onClick={ handleExitByClickingImg } aria-label={ i18n('ui.close', true) } />
        </div>
    );
}

function ImgModal({ img, alt, handleExitByClickingImg }) {
    return (
        <div className={ css.imgContent }>
            <img className={ css.img } src={ img } alt={ alt } />

            <button className={ css.imgClose } onClick={ handleExitByClickingImg } aria-label={ i18n('ui.close', true) }/>
        </div>
    );
}

export default class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view : {
                width  : 0,
                height : 0
            }
        };

        this.handleResize = this.handleResize.bind(this);
    }

    handleResize() {
        this.setState({ view : getSize() });
    }

    componentDidMount() {
        this.setState({
            view : getSize()
        });


        window.addEventListener('resize', this.handleResize);
        window.addEventListener('keydown', this.props.handleKeyNavInModal);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('keydown', this.props.handleKeyNavInModal);
    }

    render() {
        const {
            handleExitByClickingOutsideModal,
            handleExitByClickingImg,
            ytId,
            img,
            alt
        } = this.props;

        return (
            <div
                className={css.modal}
                onClick={ handleExitByClickingOutsideModal }
                tabIndex='0'
            >
                { (
                    ytId ?
                        <VideoModal view={ this.state.view } handleExitByClickingImg={ handleExitByClickingImg } ytId={ ytId } alt={ alt } /> :
                        <ImgModal img={ img } handleExitByClickingImg={ handleExitByClickingImg } alt={ alt } />
                ) }
            </div>
        );
    }
}
