import React from 'react';

import { i18n } from '../i18n';

import css   from './index.module.css';
import pvp   from './images/pvp_icon.png';
import profs from './profImgs';

const profKeys = Object.keys(profs);
const gallery  = profKeys.map(prof => profs[prof].wallpaper);

export default (props) => (
    <div className={ css.combat } id="combat">
        <div className={ css.container }>
            <img src={ pvp } alt='' />

            <div className='ieFix'>
                <h2 className={ css.header }>{ i18n('combat.header') }</h2>
                <p className={ css.para }>{ i18n('combat.para') }</p>
            </div>

            <div className={ css.professions }>
                {
                    profKeys.map((prof, idx) => (
                        <button
                            className={ css.prof }
                            onClick={ () => {
                                props.handleOpeningModal({
                                    img : profs[prof].wallpaper,
                                    idx,
                                    gallery,
                                    alt : i18n(`combat.profs.${prof}`, true)
                                });
                            } }
                            key={ idx }
                        >
                            <img
                                loading='lazy'
                                src={ profs[prof].icon }
                                alt='' // no alt, would match h4
                            />
                            <h4>{ i18n(`combat.profs.${prof}`) }</h4>
                        </button>
                    ))
                }
            </div>
        </div>
    </div>
);
