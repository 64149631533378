import engineer     from './images/engineer_icon.png';
import necromancer  from './images/necromancer_icon.png';
import thief        from './images/thief_icon.png';
import elementalist from './images/elementalist_icon.png';
import warrior      from './images/warrior_icon.png';
import ranger       from './images/ranger_icon.png';
import mesmer       from './images/mesmer_icon.png';
import guardian     from './images/guardian_icon.png';

import engineerFull     from '../combat/images/engineer_wall.jpg';
import elementalistFull from '../combat/images/elementalist_wall.jpg';
import necromancerFull  from '../combat/images/necromancer_wall.jpg';
import rangerFull       from '../combat/images/ranger_wall.jpg';
import warriorFull      from '../combat/images/warrior_wall.jpg';
import mesmerFull       from '../combat/images/mesmer_wall.jpg';
import guardianFull     from '../combat/images/guardian_wall.jpg';
import thiefFull        from '../combat/images/thief_wall.jpg';

export default {
    engineer : {
        icon      : engineer,
        wallpaper : engineerFull
    },
    necromancer : {
        icon      : necromancer,
        wallpaper : necromancerFull
    },
    thief : {
        icon      : thief,
        wallpaper : thiefFull
    },
    elementalist : {
        icon      : elementalist,
        wallpaper : elementalistFull
    },
    warrior : {
        icon      : warrior,
        wallpaper : warriorFull
    },
    ranger : {
        icon      : ranger,
        wallpaper : rangerFull
    },
    mesmer : {
        icon      : mesmer,
        wallpaper : mesmerFull
    },
    guardian : {
        icon      : guardian,
        wallpaper : guardianFull
    }
};
