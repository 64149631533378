import React from 'react';

import Logotype from '../logotype';
import { i18n } from '../i18n';

import state from '../../state/state';

import css from './index.module.css';

export default (props) => {
    const { stickyCtaRef, visible, handleCloseStickyCta, closed } = props;

    return (
        <div
            className={ visible && !closed ? css.stickycta : css.stickyctaHidden }
            ref={ stickyCtaRef }
        >
            <div className={ css.container }>
                <h3 className={ css.header }>
                    { i18n('nag.header') }
                </h3>

                <Logotype style={ css.logotype } />

                <a
                    className={ css.button }
                    href={ state.regLink }
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    { i18n('nag.cta') }
                </a>
                <button
                    className={ css.close }
                    onClick={({ currentTarget }) => {
                        handleCloseStickyCta();
                        // blur otherwise focus ring is visible
                        currentTarget.blur();
                    }}
                    aria-label="close"
                >
                    🗙
                </button>
            </div>
        </div>
    );
};
