import React from 'react';

import { setStrings } from '../components/i18n';
import state          from '../state/state';

import SEO       from '../components/seo';
import Splash    from '../components/splash';
import Intro     from '../components/intro';
import Character from '../components/character';
import Combat    from '../components/combat';
import Couture   from '../components/couture';
import Adventure from '../components/adventure';
import Cta       from '../components/cta';
import StickyCta from '../components/stickycta';
import Modal     from '../components/modal';

export default class App extends React.Component {
    constructor({ pageContext }) {
        super();

        setStrings(pageContext.strings);

        this.lang  = pageContext.lang;
        this.langs = pageContext.langs;
        this.metatags = pageContext.metatags;

        this.state = {
            ytId : false,
            img  : false,
            alt  : false,

            gallery : [],
            idx     : null,

            modalVisible       : false,
            stickyCtaIsVisible : false,
            stickyCtaIsClosed  : false
        };

        this.top    = null;
        this.bottom = null;

        this.stickyCtaRef = React.createRef();

        this.handleScroll                     = this.handleScroll.bind(this);
        this.handleOpeningModal               = this.handleOpeningModal.bind(this);
        this.handleKeyNavInModal              = this.handleKeyNavInModal.bind(this);
        this.handleExitByClickingOutsideModal = this.handleExitByClickingOutsideModal.bind(this);
        this.handleExitByClickingImg          = this.handleExitByClickingImg.bind(this);
        this.getStickyCtaVisibleBounds        = this.getStickyCtaVisibleBounds.bind(this);
        this.handleCloseStickyCta             = this.handleCloseStickyCta.bind(this);
    }

    // Sticky cta stuff
    getStickyCtaVisibleBounds() {
        const innerWidth = window.innerWidth;
        const mobileP = 512;
        const mobileL = 768;

        if (innerWidth < mobileP) {
            return {
                top    : 0,
                bottom : 7770
            };
        }

        if (innerWidth <= mobileL) {
            return {
                top    : 0,
                bottom : 7090
            };
        }

        return {
            top    : this.stickyCtaRef.current.clientHeight,
            bottom : 5600
        };
    }

    stickyIsVisible() {
        return window.pageYOffset >= this.top && window.pageYOffset <= this.bottom;
    }

    handleScroll() {
        const now = this.stickyIsVisible();

        // don't reopen the sticky bar if the modal is open
        if (this.state.stickyCtaIsVisible !== now && !this.state.modalVisible) {
            this.setState({ stickyCtaIsVisible : now });
        }
    }

    // Modal stuff
    handleOpeningModal({ img, ytId, alt, idx, gallery } = {}) {
        if (!img && !ytId) {
            return;
        }

        this.setState({
            ytId,
            img,
            alt,

            idx,
            gallery,

            modalVisible       : true,
            stickyCtaIsVisible : false
        });
    }

    handleExitByClickingOutsideModal(e) {
        if (e.target === e.currentTarget) {
            this.setState({ modalVisible : false });
        }
    }

    handleExitByClickingImg() {
        this.setState({ modalVisible : false });
    }

    handleKeyNavInModal(e) {
        let nextIdx;

        if (e.key === 'Escape') {
            this.setState({ modalVisible : false });

            return;
        }

        if (e.key === 'ArrowRight') {
            nextIdx = (this.state.idx + 1) % this.state.gallery.length;
        }

        if (e.key === 'ArrowLeft') {
            nextIdx = (this.state.idx - 1 + this.state.gallery.length) % this.state.gallery.length;
        }

        this.setState({
            idx : nextIdx,
            img : this.state.gallery[nextIdx]
        });
    }

    handleCloseStickyCta() {
        this.setState({ stickyCtaIsClosed : true });
    }

    componentDidMount() {
        const { top, bottom } = this.getStickyCtaVisibleBounds();

        this.top = top;
        this.bottom = bottom;

        window.addEventListener('scroll', this.handleScroll);

        const cid = (document.location.search || '').match(/cid=([^&]*)/);

        // tag on cid param to reg link
        if (cid && cid[1]) {
            state.regLink = `${state.regLink}?cid=${cid[1]}`;
        }
    }

    render() {
        const { lang, langs, metatags } = this;
        const { ytId, img, alt, idx, gallery, modalVisible } = this.state;

        return (
            <div>
                <SEO lang={lang} metatags={metatags} langs={langs} />
                <Splash handleOpeningModal={this.handleOpeningModal} />
                <Intro />
                <Character />
                <Combat
                    handleOpeningModal={this.handleOpeningModal}
                    professions={this.professions}
                />
                <Couture />
                <Adventure />
                <Cta />
                <StickyCta
                    handleCloseStickyCta={this.handleCloseStickyCta}
                    stickyCtaRef={this.stickyCtaRef}
                    visible={this.state.stickyCtaIsVisible}
                    closed={this.state.stickyCtaIsClosed}
                />
                {(modalVisible ?
                    <Modal
                        ytId={ytId}
                        img={img}
                        alt={alt}

                        gallery={gallery}
                        idx={idx}

                        handleKeyNavInModal={this.handleKeyNavInModal}
                        handleExitByClickingOutsideModal={this.handleExitByClickingOutsideModal}
                        handleExitByClickingImg={this.handleExitByClickingImg}
                    /> :
                    null
                ) }
            </div>
        );
    }
}
