import React from 'react';

import { i18n } from '../i18n';

import css from './index.module.css';

export default () => (
    <div className={ css.character } id="character">
        <div className={ css.be }>
            <h2 className={ css.header }>{ i18n('who.header') }</h2>
            <p className={ css.para }>{ i18n('who.para') }</p>
        </div>

        <h3 className={ css.tagline }>{ i18n('who.tagline') }</h3>
    </div>
)
