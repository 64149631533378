import React    from 'react';
import Markdown from 'markdown-to-jsx';
import get      from 'object-get';

import css from './index.module.css';

let debug = false;
let strings;

/**
 * Render text/markdown, unfortunately wraps text in a span
 * @param {string} path - path to string, eg intro.header
 * @param {boolean} [plain] - don't render as markdown, just text (for metatags etc)
 */
export function i18n(path, plain) {
    if (!path) {
        return 'i18n() requires a path, eg i18n(\'intro.header\')';
    }

    const foundString = get(strings, path) || `[[not found: ${path}]]`;

    if (plain) {
        return foundString;
    }

    if (debug) {
        return <Markdown data-i18n={path} className={css.debug}>{ foundString }</Markdown>;
    }

    return <Markdown>{ foundString }</Markdown>;
}

export function setStrings(_strings) {
    strings = _strings;
}
